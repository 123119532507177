import { getOwner as go } from "solid-js";
// owner.owner.component.name
export function getOwner(options?: { depth?: number }) {
  const components: { name: string; location: string }[] = [];
  const depth = options?.depth ?? 5;
  let owner = go();
  let count = 0;
  // console.log("owner :: ", owner.owner.component.name, " :: ", "component" in owner.owner);
  while ("component" in owner.owner) {
    const comp = owner?.owner?.component as any;
    if (comp) {
      const obj = {
        name: comp?.name?.substring(comp?.name?.lastIndexOf("]") + 1),
        location: comp?.location,
      };
      components.push(obj);
    }
    owner = owner?.owner?.owner;
    count++;
    if (count >= depth) {
      break;
    }
  }
  return {
    component: components.shift(),
    tree: components,
    owner,
  };
}
